import {
  FR,
  NL,
  VL,
  DE,
  EN,
  ES,
  DEFAULT_APPROVAL_FILTER,
  TEST,
} from "./constants";

const config = {
  clientCode: TEST,
  clientId: 10000,
  lang: [NL, VL, FR, DE, EN, ES],
  defaultLang: NL,
  uiSettings: {
    brandSelection: true,
    languageSwitch: true,
    toolsMenu: false,
    downloadsMenu: false,
    showPrices: true,
    showCustomerApproval: true,
    approvalFilter: DEFAULT_APPROVAL_FILTER,
    showAvailableCycles: true,
    showMigrationLink: false,
    sidebarFAQ: null,
    showFooterText: true,
    maintenanceMessage: null,
    showCalendar: true,
    showSuppliers: true,
    showRequestAccountButton: true,
    showSidebarAL: true,
    showLogos: false,
    showSignatures: true,
    weekendParticipationDate: true, // false = first available part.date should not be on a weekend
    participationDateDelay: 21, // default: 21 days
    showParticipationDate: false,
  },
  downloadsPageReport: {
    reportId: null,
    viewId: null,
  },
};

export default config;
