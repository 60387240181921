import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { darken, opacify } from "polished";
import { media } from "styled-bootstrap-grid";

const GlobalStyle = createGlobalStyle`
  ${normalize};
  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }
  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
  banner,
  section,
  header,
  footer,
  img,
  nav,
  iframe {
    display: block;
    width: 100%;
  }
  body {
    background-color: ${(props) => props.theme.background};
    background-image: repeating-linear-gradient(
      -45deg,
      transparent 0px,
      transparent 1px,
      rgba(0, 0, 0, 0.04) 3px,
      rgba(0, 0, 0, 0.04) 4px
    );
    background-size: 5px 5px;
    font-family: ${(props) => props.theme.mainFont};
    font-size: 13px;
    line-height: 1.6;
    color: ${(props) => props.theme.textColor};
    padding-top: 1.5em;
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: default;
    padding-right: 1em;
    padding-left: 1em;
    @media (max-width: 575px) {
      padding-top: 8px;
    }
  }
  strong {
    font-weight: 600;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
    margin-bottom: 0.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4;
  }
  h1,
  h2,
  h3 {
    color: ${(props) => props.theme.headingColor};
    font-weight: 500;
  }
  h1 {
    font-size: 2.5em;
    font-weight: normal;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .font-monospace {
    font-family: monospace;
  }
  a {
    color: ${(props) => props.theme.linkColor};
    transition: color 0.2s, border-color 0.2s, background 0.2s;
    &.no-decoration {
      text-decoration: none;
      color: inherit;
      &:hover {
        color: inherit;
      }
    }
    &:hover {
      color: ${(props) => darken(0.05, props.theme.linkColor)};
    }
  }
  .dropdown-item {
    transition: background 0.2s, color 0.2s;
    &:hover {
      background: ${(props) => props.theme.hoverColor};
    }
    .dropdown-menu-icon {
      margin-left: -1em;
      font-size: 1.4em;
      color: ${(props) => opacify(-0.4, props.theme.accentColor)};
      margin-right: 0.6em;
      vertical-align: -0.2em;
    }
    .dropdown-item-with-icon-meta {
      padding-left: 1em;
    }
  }
  .select-menu-max-height {
    ul {
      max-height: 224px;
    }
  }
  .icon-list {
    list-style: none;
    padding-left: 0;
    li {
      margin-bottom: 0.75em;
    }
    svg {
      margin-bottom: -0.25em;
      margin-right: 0.4em;
      font-size: 1.5em;
      color: ${(props) => props.theme.iconColor};
    }
  }
  .logo-link {
    display: flex;
    text-decoration: none;
    min-width: 180px;
    cursor: pointer;
    padding: 1.3em;
    align-items: center;
    min-height: 7em;
    img {
      width: auto;
      max-width: 100%;
      max-height: 90px;
    }
    @media (max-width: ${(props) => props.theme.menuBreakpoint}) {
      padding: 0;
      height: 48px;
      min-height: auto;
      img {
        max-height: 90%;
        max-width: 75%;
      }
    }
  }
  .logo-img {
    object-fit: contain;
    &.logo-fixed {
      width: auto;
      max-width: calc(80% - 3em);
      max-height: 90px;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      margin: 2em;
    }
    &.logo-custom-BNFGROEP, &.logo-custom-ACOUSTICSNL,
    &.logo-custom-ESSNL,
    &.logo-custom-ESSBE,
    &.logo-custom-TEST {
      height: 90px;
      width: auto;
    }
    &.logo-custom-OOGWERELDNL,
    &.logo-custom-OOGWERELDBE {
      height: 52px;
      width: auto;
    }
    &.logo-custom-OPTICIANSUK {
      height: 90px;
      width: auto;
      margin-bottom: -5px;
      padding: 10px;
    }
    &.logo-custom-TEMATICAES {
      height: 70px;
      width: auto;
      padding: 5px;
    }
    &.logo-custom-EYECARENL {
      height: 88px;
      width: auto;
      padding: 10px;
    }
    &.logo-custom-Volvo {
      padding: 5px;
      height: 32px;
      width: auto;
      &.logo-fixed {
        width: 200px;
      }
    }
    &.logo-custom-BIKENL {
      height: 90px;
      width: auto;
      padding: 3px;
    }
    &.logo-custom-EGSDE, &.logo-custom-RSDE {
      height: 32px;
      width: auto;
    }
  }
  .lang-container {
    position: fixed;
    right: 0;
    top: 0;
    margin: 2em;
    z-index: 2;
  }
  .banner-img-wrap {
    overflow: hidden;
    position: relative;
    padding-bottom: 60%;
    img {
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .icon-link {
    svg {
      margin-right: 0.4em;
      opacity: 0.75;
    }
  }
  .icon-inline {
    font-size: 1.2em;
    line-height: 1;
    vertical-align: -0.2em;
    margin-right: 0.5em;
    opacity: 0.4;
    overflow: visible;
  }
  .icon-color {
    opacity: 1;
    color: ${(props) => props.theme.iconColor};
  }
  .list-icon {
    font-size: 1.2em;
    line-height: 1;
    vertical-align: -0.15em;
    margin-right: 0.5em;
    color: ${(props) => props.theme.iconColor};
    svg {
      overflow: visible;
    }
  }
  .list-label {
    opacity: 0.7;
  }
  .min-width-auto {
    min-width: auto;
  }
  .dropdown-min-width {
    min-width: 150px;
  }
  .dialog-modal {
    right: 0;
    left: 0;
    margin: auto;
    max-width: 95%;
  }
  .modal-footer {
    padding: 1.2em;
    border-top: 1px solid ${(props) => props.theme.borderColor};
    .modal-footer-item {
      margin-left: 1em;
    }
  }
  .react-tiny-popover-container {
    z-index: 2;
  }
  *:focus-visible,
  button:focus-visible,
  [type="button"]:focus-visible,
  [type="reset"]:focus-visible,
  [type="submit"]:focus-visible {
    outline: none;
  }
  .global-alert {
    box-shadow: none;
  }

  // Utils
  .no-decoration {
    text-decoration: none;
  }
  .clearfix::before,
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  .row-wrap {
    padding: 0 8px;
  }
  .row-wrap-sm {
    padding: 0 3px;
  }
  .row-wrap-no-gutter {
    padding: 0 16px;
  }
  .border {
    border: 1px solid ${(props) => props.theme.borderColor};
  }
  .border-top {
    border-top: 1px solid ${(props) => props.theme.borderColor};
  }
  .border-bottom {
    border-bottom: 1px solid ${(props) => props.theme.borderColor};
  }
  .text-right {
    text-align: right;
  }
  .padding-top {
    padding-top: 1em;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block;
  }
  .date {
    svg {
      font-size: 1.2em;
      vertical-align: -0.15em;
      color: ${(props) => opacify(-0.6, props.theme.accentColor)};
    }
  }
  .font-weight-normal {
    font-weight: normal !important;
  }
  .font-weight-semibold {
    font-weight: 600;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .hover-zoom {
    img,
    .zoom {
      transition: transform 0.4s;
    }
    &:hover {
      img,
      .zoom {
        transform: scale(1.1, 1.1);
      }
    }
  }
  .hover-slide-right {
    .slide {
      transition: transform 0.2s;
    }
    &:hover {
      .slide {
        transform: translateX(2px);
      }
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .hide-md-up {
    @media (min-width: 768px) {
      display: none !important;
    }
  }
  .hide-sm-down {
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  .border-left-md {
    @media (min-width: 768px) {
      border-left: 1px solid ${(props) => props.theme.borderColor};
    }
    @media (max-width: 767px) {
      border-top: 1px solid ${(props) => props.theme.borderColor};
    }
  }
  .grid-form-label {
    display: inline-flex;
    min-height: 40px;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    ${media.xs`
            justify-content: flex-start;
            min-height: 2em;
        `}
  }
  .margin-top {
    margin-top: 1.2em;
  }
  .margin-top-small {
    margin-top: 0.6em;
  }
  .margin-bottom {
    margin-bottom: 1.2em;
  }
  .margin-bottom-small {
    margin-bottom: 0.6em;
  }
  .padding-top {
    padding-top: 1.2em;
  }
  .padding-top-small {
    padding-top: 0.6em;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  .smaller {
    font-size: 0.95em;
  }
  .color-secondary {
    color: ${(props) => props.theme.secondaryColor};
  }
  .overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    white-space: nowrap;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  @media print {
    body {
      padding: 0;
      background: none;
    }
    html {
      padding: 0;
    }
    .no-print {
      display: none !important;
    }
    .card-header,
    .card-footer {
      background: none;
    }
  }
  .btn-pills-wrap {
    padding: 0.2em 0;
    margin: 0 -0.2em 0.2em;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    button,
    .button {
      margin: 0.2em 0.3em;
      flex-grow: 1;
      padding-left: 0.6em;
      padding-right: 0.6em;
    }
  }
  .popover-arrow {
    bottom: 2px !important;
    height: 10px;
  }
  .icon-trigger {
    font-size: 150%;
    line-height: 1;
    vertical-align: middle;
    color: ${(props) => props.theme.iconColor};
    transition: color 0.2s;
    &:hover {
      color: ${(props) => props.theme.accentColor};
    }
  }
  .action-btn-wrap {
    font-size: 75%;
    button {
      padding: 0.4em;
      height: auto;
    }
  }

  .success-color {
    color: ${(props) => props.theme.successColor};
  }
  .success-bg {
    background: ${(props) => props.theme.successColor};
  }
  .error-color {
    color: #bd5656;
  }
  .modal-footer.mob-btn {
    button.hide-label-xs {
      svg:first-child:not(:only-child) {
        margin-right: 0;
      }
      svg + span {
        margin-left: 0.6em;
        @media (max-width: 575px) {
          display: none;
        }
      }
    }
    @media (max-width: 575px) {
      .modal-footer-item {
        margin: 0 0.3em;
        width: 100%;
        button {
          width: 100%;
        }
        svg + span {
          display: none;
        }
      }
    }
  }
  .modal-header {
    padding-left: 1.2em;
    padding-right: 1.2em;
  }
  .modal-close-btn {
    right: 13px;
    top: 13px;
  }

  // Content page
  .content-block {
    font-size: 14px;
    line-height: 1.6;
    h1, h2, h3, h4, h5, h6, p {
      margin-bottom: 7px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    ul,
    ol {
      padding-left: 1.7em;
      margin-bottom: 1em;
      margin-top: .75em;
      &:last-child {
        margin-bottom: 0;
      }
      li {
        margin-top: 0;
        margin-bottom: 0.15em;
      }
    }
    .html-block {
      margin-bottom: 7px;
    }
  }

  // Effects
  .delay1 {
    animation-delay: 0.1s;
  }
  .delay2 {
    animation-delay: 0.2s;
  }
  .delay3 {
    animation-delay: 3s;
  }
  .fadeInGrow {
    animation-name: fadeInGrow;
    animation-duration: 0.1s;
  }
  @keyframes fadeInGrow {
    from {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }
    to {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  .fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.3s;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadeInDown {
    animation-name: fadeInDown;
    animation-duration: 0.3s;
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 0.3s;
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .spin {
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes spin {
    from {
      transform: rotate(-360deg);
    }
    to {
      transform: rotate(0);
    }
  }

  // Action-lists 
  .al-card {
    .card-body {
      padding-bottom: 8px;
    }
  }
  .overflow-hidden {
    .al-card {
      .card-body {
        padding-bottom: 0;
      }
    }
  }
  .select-bg, .select-bg option {
    background: rgba(255,255,255,.7)
  }
  body.overflow-hidden .main-card .card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 6em);
  }

  // Quicksight embed container
  .overflow-hidden {
    overflow: hidden;
  }
  #static-container.hidden {
    visibility: hidden;
    height: 0;
    display: none;
  }
  #static-container {
    max-width: 1326px;
    width: calc(100% - 1em - 1px);
    margin-right: auto;
    margin-left: auto;
    margin-top: -65px;
    margin-bottom: 1.2em;
    position: relative;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.22) 0px 5px 5px;
    overflow: hidden auto;
    min-height: 70vh;
    background: white;
  }
  #static-container.fullscreen {
    max-width: none;
    width: auto;
    margin-right: -1em;
    margin-left: -1em;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    height: calc(100vh - 57px);
  }
  #static-container:not(.fullscreen) {
    border-top: 1px solid #e5e5e5;
  }
  #static-container:not(.hidden) iframe {
    min-height: 70vh;
  }
  #static-container iframe:not(:last-child) {
    display: none;
  }
  #static-container.fullscreen:not(.hidden) iframe {
    min-height: calc(100vh - 57px);
  }
  #static-container iframe {
    opacity: 0;
    transition: opacity 0.2s;
  }
  #static-container.qs-loaded iframe {
    opacity: 1;
  }
  #static-container:not(.qs-loaded) {
    background-image: url("/img/spinner.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50%;
  }
`;

export default GlobalStyle;
